@media (max-width: 600px) {
    .typewriter-text {
      font-size: 1.5rem;
    }
    .project-card img {
      width: 100%;
    }
    .core-text{
        font-size: 1.05rem !important;
    }
    .core-image{
        display: none;
    }
    .experience-text{
        margin-top: 0px !important;
    }
  }